import { get, post, put, delete as _delete } from "axios";

const apiUrl = `//${location.hostname}`;
// const apiUrl = "https://api.festival.tf:2999";

const ErrorHandler = (error) => {
	if (error.response) {
		if (error.response.data instanceof Object) return error.response.data;
		return {
			error: {
				code: error.response.status,
				message: error.message
			}
		};
	}

	console.error(error)
}

//#region Account API
export class AccountAPI {
	static async getAccount(params) {
		return get(`//api.festival.tf/account`)
			.then(response => response.data)
			.catch(error => ErrorHandler(error));
	}
}
//#endregion

//#region Status API
export class StatusAPI {
	static async getServices() {
		return get(`${apiUrl}/system-status.json`)
			.then(response => response.data)
			.catch(error => ErrorHandler(error));
	}

	static async getEvents() {
		return get(`${apiUrl}/event`)
			.then(response => response.data)
			.catch(error => ErrorHandler(error));
	}

	static async createEvent(eventData) {
		return post(`${apiUrl}/event/`, eventData)
			.then(response => response.data)
			.catch(error => ErrorHandler(error));
	}

	static async updateEvent(eventId, eventData) {
		return put(`${apiUrl}/event/${eventId}`, eventData)
			.then(response => response.data)
			.catch(error => ErrorHandler(error));
	}

	static async deleteEvent(eventId) {
		return _delete(`${apiUrl}/event/${eventId}`)
			.then(response => response.data)
			.catch(error => ErrorHandler(error));
	}
}
//#endregion