import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import vueHeadful from "vue-headful";
Vue.component("vue-headful", vueHeadful);

import "intersection-observer"
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);

import bcp47 from "bcp47";
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: bcp47.parse(navigator.language).langtag.language.language,
	fallbackLocale: "en",
	messages: {
		en: require("@/locale/en.json"),
		de: require("@/locale/de.json")
	}
});

import axios from 'axios';
import VueAxios from 'vue-axios';
axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
	router,
	store,
	i18n,
	render: function(h) { return h(App) },
}).$mount('#app')
