const OrganizationRole = {
	NONE: 0,
	DEVELOPER: 2
}

const UserRole = {
	NONE: -1,
	MIGRATE: 1,
	USER: 2,
	DEVELOPER: 4,
	MODERATOR: 8,
	ADMINISTRATOR: 16,
	ROOT: 1 | 2 | 4 | 6 | 8 | 16
};

module.exports = {
	OrganizationRole,
	UserRole
}