import Vue from "vue"
import VueRouter from "vue-router"
import store from '../store'

import { UserRole } from '../../../../shared/festival-next-shared/utilities/Enumerations'
import { AccountAPI } from "@/scripts/ApiUtil";

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "Dashboard",
			component: () => {
				return import(/*webpackChunkName: "dashboard" */ "../pages/Dashboard/template.vue")
			},
			meta: {
				allowAnonymous: true
			}
		},
		{
			path: "/events",
			name: "Events",
			component: () => {
				return import(/*webpackChunkName: "events" */ "../pages/Events/template.vue")
			},
			meta: {
				requiredRole: UserRole.ROOT
			}
		}
	]
});

router.beforeEach(async (to, from, next) => {
	if (!to.meta.allowAnonymous) {
		if (!Vue.$cookies.get("user.id")) {
			store.commit("setAccountId", null);
			store.commit("setRole", null);

			window.location = `https://account.festival.tf/sign-in?next=${String.prototype.concat(location.origin, to.path)}`;
			return;
		}

		let accountObj = await AccountAPI.getAccount();
		if (!accountObj || accountObj.error) {
			window.location = `https://account.festival.tf/sign-in?next=${String.prototype.concat(location.origin, to.path)}`;
			return;
		}

		store.commit("setAccountId", accountObj.id);
		store.commit("setRole", accountObj.role);

		if (to.meta.requiredRole && (accountObj.role & to.meta.requiredRole) !== to.meta.requiredRole) {
			return next({
				path: from.path,
				replace: true
			});
		}
	}

	next();
});

export default router
